import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { useCard } from '../../../contexts/cardContext';

import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';


import { MdOutlineFilterList, MdGroups, MdOutlineSearch, MdOutlineUpdate } from "react-icons/md";
import { MdPerson, MdPhone, MdAttachMoney, MdDescription, MdLocalShipping, MdLocationCity, MdLabel, MdPublic } from 'react-icons/md';



import logoDefault from '../../../assets/logo-suite-flow.ico'

import logoPrefweb from '../../../assets/logo-prefweb.PNG'

import logoInstalacao from '../../../assets/logo-instalacao.PNG'
import logoMontagem from '../../../assets/logo-montagem.PNG'


import { useNavigate } from 'react-router-dom';

import './style.css';



function PortalPage() {
  const { user, empresa } = useUser(); // Reduzi para mostrar apenas variáveis usadas neste trecho


  const [conteudoSelecionado, setConteudoSelecionado] = useState(null);
  const [topicoSelecionado, setTopicoSelecionado] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState(null);



  const [conteudo, setConteudo] = useState([
    { id: 1, logo: logoPrefweb, description: 'PrefWeb' },
    { id: 2, logo: '', description: 'SyncsCRM' },
    { id: 3, logo: logoInstalacao, description: 'Instalação' },
    { id: 4, logo: logoMontagem, description: 'Produção' },
  ]);


  const [topicos, setTopicos] = useState([
    { id: 1, conteudo_id: 1, description: 'Tipologias' },
    { id: 2, conteudo_id: 1, description: 'Vidros' },
    { id: 3, conteudo_id: 1, description: 'Componentes' },
    { id: 4, conteudo_id: 1, description: 'Perfis' },

    { id: 5, conteudo_id: 2, description: 'Dashboard' },
    { id: 6, conteudo_id: 2, description: 'Formulário de Pedidos' },
    { id: 7, conteudo_id: 2, description: 'Anexos' },
    { id: 8, conteudo_id: 2, description: 'PCP' },

    { id: 9, conteudo_id: 3, description: 'Silicone' },
    { id: 10, conteudo_id: 3, description: 'Preparação de vão' },
    { id: 11, conteudo_id: 3, description: 'Pingadeiras' },
    { id: 12, conteudo_id: 3, description: 'Espuma' },

    { id: 13, conteudo_id: 4, description: 'Montagem' },
    { id: 14, conteudo_id: 4, description: 'Envidraçamento' },
    { id: 15, conteudo_id: 4, description: 'Expedição' },
    { id: 16, conteudo_id: 4, description: 'Cuidados' },
  ]);


  const [midia, setMidia] = useState([
    { id: 1, topico_id: 1, description: 'Tipos de Tipologias', link: 'https://www.youtube.com/embed/l482T0yNkeo' },
    { id: 2, topico_id: 1, description: 'Como alterar o vidro', link: 'https://www.youtube.com/embed/3JZ_D3ELwOQ' },
    { id: 3, topico_id: 1, description: 'Como alterar o tipo de fecho', link: 'https://www.youtube.com/embed/l482T0yNkeo' },
    { id: 4, topico_id: 1, description: 'Como orçar perfis separados', link: 'https://www.youtube.com/embed/l482T0yNkeo' },

    { id: 5, topico_id: 2, description: 'Analise de vendas', link: 'https://www.youtube.com/embed/tgbNymZ7vqY' },
    { id: 6, topico_id: 2, description: 'Como solicitar um novo pedido', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 7, topico_id: 2, description: 'Como incluir anexos', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },

    { id: 9, topico_id: 3, description: 'Como aplicar o silicone de forma correta', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 11, topico_id: 3, description: 'Cuidados com as pingadeiras', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },

    { id: 13, topico_id: 4, description: 'Detalhes sobre a montagem de esquadrias', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 14, topico_id: 4, description: 'Como envidraçar os vidros', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 15, topico_id: 4, description: 'Forma correta de embalar uma esquadria', link: 'https://www.youtube.com/embed/MtN1YnoL46Q' },
    { id: 16, topico_id: 4, description: 'Cuidados no transporte,Cuidados no transporte,Cuidados no transporte Cuidados no transporte Cuidados no transporte', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 17, topico_id: 4, description: 'Detalhes sobre a montagem de esquadrias', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 18, topico_id: 4, description: 'Como envidraçar os vidros', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 19, topico_id: 4, description: 'Forma correta de embalar uma esquadria', link: 'https://www.youtube.com/embed/MtN1YnoL46Q' },
    { id: 20, topico_id: 4, description: 'Cuidados no transporte,Cuidados no transporte,Cuidados no transporte Cuidados no transporte Cuidados no transporte', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 21, topico_id: 4, description: 'Detalhes sobre a montagem de esquadrias', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 22, topico_id: 4, description: 'Como envidraçar os vidros', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 23, topico_id: 4, description: 'Forma correta de embalar uma esquadria', link: 'https://www.youtube.com/embed/MtN1YnoL46Q' },
    { id: 24, topico_id: 4, description: 'Cuidados no transporte,Cuidados no transporte,Cuidados no transporte Cuidados no transporte Cuidados no transporte', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 25, topico_id: 4, description: 'Detalhes sobre a montagem de esquadrias', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 26, topico_id: 4, description: 'Como envidraçar os vidros', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
    { id: 27, topico_id: 4, description: 'Forma correta de embalar uma esquadria', link: 'https://www.youtube.com/embed/MtN1YnoL46Q' },
    { id: 28, topico_id: 4, description: 'Cuidados no transporte,Cuidados no transporte,Cuidados no transporte Cuidados no transporte Cuidados no transporte', link: 'https://www.youtube.com/embed/xvFZjo5PgG0' },
  ]);



  const handleConteudoClick = (id) => {
    setConteudoSelecionado(id);
    setTopicoSelecionado(null); // Resetar seleção de tópicos
  };

  const handleTopicoClick = (id) => {
    setTopicoSelecionado(id);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };
  const closeModal = () => {
    setSelectedVideo(null);
  };


  const getYouTubeID = (url) => {
    const regExp = /^.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[1].length === 11 ? match[1] : null;
  };



  return (
    <div className='portal-page-container'>
      <Header />
      <div className='body-portal-container'>
        {/* Listar Conteúdos */}
        <div className='list-conteudo-principal'>
          {conteudo.map((item) => (
            <div
              key={item.id}
              className='item-list-conteudo-principal'
              onClick={() => handleConteudoClick(item.id)}
            >
              <div className='content-header'>
                <img
                  src={item.logo || logoDefault}
                  alt={`${item.description} logo`}
                  className='item-logo'
                />
              </div>
              <div className='content-description'>
                <h4>{item.description}</h4>
              </div>
            </div>
          ))}
        </div>

        {/* Listar Tópicos */}
        {conteudoSelecionado && (
          <div className='list-topicos'>
            <h3>Tópicos</h3>
            <div className='list-midia-container'>
              {topicos
                .filter((topico) => topico.conteudo_id === conteudoSelecionado)
                .map((topico) => (
                  <div
                    key={topico.id}
                    className='item-list-topico'
                    onClick={() => handleTopicoClick(topico.id)}
                  >
                    {topico.description}
                  </div>
                ))}
            </div>
          </div>
        )}
        {topicoSelecionado && (
          <div className='list-midia'>
            <h3>Mídias</h3>
            <div className='list-midia-container'>
              {midia
                .filter((m) => m.topico_id === topicoSelecionado)
                .map((m) => (

                  <div
                    key={m.id}
                    className='item-list-midia'
                    onClick={() => handleVideoClick(m)}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${getYouTubeID(m.link)}/hqdefault.jpg`}
                      alt={m.description}
                      className='video-thumbnail'
                    />
                    <p className='midia-description'>{m.description}</p>
                  </div>



                ))}
            </div>
          </div>
        )}

        {/* Modal de Vídeo */}
        {selectedVideo && (
          <div className='modal-portal'>
            <div className='modal-content-portal'>
              <button className='close-button-portal' onClick={closeModal}>
                Fechar
              </button>
              <iframe
                src={selectedVideo.link}
                title={selectedVideo.description}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>{selectedVideo.description}</p>
            </div>
          </div>
        )}



      </div>
    </div>
  );
}

export default PortalPage;